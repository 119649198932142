import React, {useState} from 'react'
import Layout from '../components/layout';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import { graphql } from "gatsby"
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import Seo from '../components/seo';

const SellYourCarPage = (location) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handlePost = (event) => {
        let myForm = document.getElementById('myform');
        let formData = new FormData(myForm)

        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
            .then(
                navigate("/thank-you")
            )
            .catch((error) =>
                setShowErrorMessage(true)
            )
    }

    return (
        <Layout location={location}>
            <Seo
                title={`${t('SellYourCar')}`}
                description={t('SellYourCarMetaDescription')}
            />
            <div className="c-block c-block--first">
                <div className="site-width">
                    <h2 className="c-block__title">{t('SellYourCar')}</h2>

                    <div className="c-block__text">
                        <p className="text-content">
                            {t('SellYourCarDescription')}
                        </p>
                        <form name="sellyourcar" method="POST" id="myform" onSubmit={handleSubmit(handlePost)} data-netlify="true">
                            <input type="hidden" name="bot-field" />    
                            <input type="hidden" name="form-name" value="sellyourcar" />
                            <fieldset>
                                <div style={{ textTransform: 'uppercase', fontWeight: '600', paddingBottom: '16px' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.2)', fontSize: '28px' }}>
                                        01. {t('CarInfo')}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label for="make">{t('Make')} *</label>
                                    <input className={classNames({ 'form-input': true, error: errors.make })} type="text" {...register('make', { required: true })} />
                                    {errors.make && <p className="form-input-validation">{t('FieldIsRequired', { field: t('Make') })}</p>}
                                </div>

                                <div className="form-group">
                                    <label for="model">{t('Model')} *</label>
                                    <input className={classNames({ 'form-input': true, error: errors.model })} type="text" {...register('model', { required: true })} />
                                    {errors.model && <p className="form-input-validation">{t('FieldIsRequired', { field: t('Model') })}</p>}
                                </div>

                                <div className="form-group">
                                    <label for="year">{t('Year')} *</label>
                                    <input className={classNames({ 'form-input': true, error: errors.year })} type="text" {...register('year', { required: true })} />
                                    {errors.year && <p className="form-input-validation">{t('FieldIsRequired', { field: t('Year') })}</p>}
                                </div>

                                <div className="form-group">
                                    <label for="year">{t('ApproxMileage')} *</label>
                                    <input className={classNames({ 'form-input': true, error: errors.kilometers })} type="text" {...register('kilometers', { required: true })} />
                                    {errors.kilometers && <p className="form-input-validation">{t('FieldIsRequired', { field: t('SpecMileage') })}</p>}
                                </div>
                            </fieldset>

                            <fieldset>
                                <div style={{ textTransform: 'uppercase', fontWeight: '600', paddingBottom: '16px' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.2)', fontSize: '28px' }}>
                                        02. {t('CarCondition')}
                                    </span>
                                </div>
                                <p>{t('AccidentFree')} *</p>
                                <div>
                                    <input className={classNames({ 'form-input--radio': true, error: errors.accidentFree })} id="accident-free-yes" type="radio" {...register('accidentFree', { required: true })} />
                                    {/* <input className="form-input--radio" type="radio" value="yes" id="accident-free-yes" name="accident-free" /> */}
                                    <label for="accident-free-yes">{t('Yes')}</label>
                                </div>
                                <div>
                                    <input className={classNames({ 'form-input--radio': true, error: errors.accidentFree })} id="accident-free-no" type="radio" {...register('accidentFree', { required: true })} />
                                    <label for="accident-free-no">{t('No')}</label>
                                </div>
                                <div>
                                    <input className={classNames({ 'form-input--radio': true, error: errors.accidentFree })} id="accident-free-unknown" type="radio" {...register('accidentFree', { required: true })} />
                                    <label for="accident-free-unknown">{t('IDontKnow')}</label>
                                </div>
                                {errors.accidentFree && <p className="form-input-validation">{t('FieldIsRequired', { field: t('AccidentFreeShort') })}</p>}
                            </fieldset>

                            <fieldset>
                                <div style={{ textTransform: 'uppercase', fontWeight: '600', paddingBottom: '16px' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.2)', fontSize: '28px' }}>
                                        03. {t('ContactInformation')}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label for="firstname">{t('FirstName')} *</label>
                                    <input className={classNames({ 'form-input': true, error: errors.firstName })} type="text" {...register('firstName', { required: true })} />
                                    {errors.firstName && <p className="form-input-validation">{t('FieldIsRequired', { field: t('FirstName') })}</p>}
                                </div>

                                <div className="form-group">
                                    <label for="firstname">{t('EmailAddress')} *</label>
                                    <input className={classNames({ 'form-input': true, error: errors.emailAddress })} type="email" {...register('emailAddress', { required: true })} />
                                    {errors.emailAddress && <p className="form-input-validation">{t('FieldIsRequired', { field: t('EmailAddress') })}</p>}
                                </div>

                                <div className="form-group">
                                    <label for="firstname">{t('PhoneNumber')}</label>
                                    <input className={classNames({ 'form-input': true })} type="text" {...register('phoneNumber', { required: true })} />
                                </div>
                            </fieldset>
                            {
                                showErrorMessage &&
                                <div>
                                    Er ging iets mis bij het verzenden van het formulier. Probeer het later opnieuw of neem rechtstreeks contact met ons op via <a href="tel:+32472379980">+32 472 37 99 80</a> of <a href="mailto:axel@autohunt.be">axel@autohunt.be</a>
                                </div>
                            }
                            <input className="main-cta" type="submit" value={t('Send')} />
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SellYourCarPage;

export const query = graphql`
query ($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
      }
`